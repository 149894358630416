import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import NotAllowed from './NotAllowed/NotAllowed';
import Create from './Create/Create';
import Archive from './Archive/Archive';
import VXCashUsers from '../../Lists/VXCashUsers/VXCashUsers';
import VXFavourites from '../../Lists/VXFavourites/VXFavourites';

import MarketingStore from '../../../stores/Marketing/MarketingStore';
import MarketingActionCreators from '../../../stores/Marketing/MarketingActionCreators';

import { AbstractComponent, Footer, Spinner, T, Tooltip } from '../../../components';

import { getFormattedDate } from '../../../util/Formatter';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const STORE = 'marketing.admails';

class Admails extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getStateFromStores(),
      loadingDone: false,
    };
  }

  componentDidMount() {
    MarketingStore.addChangeListener(STORE, this.onStoreChange);
    MarketingActionCreators.getAdmailInfos();
  }

  componentWillUnmount() {
    MarketingStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange() {
    let stateFromStores = cloneDeep(this.getStateFromStores());
    this.setState({
      ...stateFromStores,
      loadingDone: true,
    });
  }

  getStateFromStores() {
    let storeData = MarketingStore.get(STORE);

    let tmpDate;
    for (let i in storeData.receiverOptions) {
      storeData.receiverOptions[i].label =
        _('marketing:admails.receiver.' + storeData.receiverOptions[i].value) +
        ' (' +
        storeData.receiverOptions[i].amount +
        ')';

      switch (storeData.receiverOptions[i].value) {
        case 'favourites':
          storeData.receiverOptions[i].icon = 'icon-heart';
          break;
        case 'promolinkUsers':
          storeData.receiverOptions[i].icon = 'icon-wifi-alt';
          break;
        default:
          storeData.receiverOptions[i].icon = '';
      }

      tmpDate = new Date(storeData.receiverOptions[i].nextAdmailDate);
      if (tmpDate.toString() !== 'Invalid Date') {
        const [{ lang }] = this.props.appState;

        storeData.receiverOptions[i].sublabel =
          _('common:text.from') +
          ' ' +
          getFormattedDate(storeData.receiverOptions[i].nextAdmailDate, lang);
      }
    }
    return {
      storeData: storeData,
      archiveSortField: 'createdDate',
      archiveSortDirection: 'desc',
    };
  }

  render() {
    return (
      <main id="marketing-admails" className="grid marketing marketing-admails">
        <div className="grid__row min-width--0">
          <div className="grid__column grid__box h--pos-rel min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'marketing:admails.block.admails'} />
              </div>
            </div>

            <div className="grid__box__row">
              <div className="grid__box__column grid__box__sub min-width--0">
                {this.state.storeData.isAdmailingAllowed && (
                  <Fragment>
                    <div className="grid__box__sub__header">
                      <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                        <T _={'marketing:admails.allowed.title'} />
                      </div>
                      <Tooltip position="right" text={_('marketing:admails.tooltip.admailTitle')} />
                    </div>

                    <div className="grid__box__sub__item">
                      <div className="grid__box__sub__item__content spinner-container min-width--0">
                        {!this.state.loadingDone && <Spinner />}

                        <Create
                          receiverOptions={this.state.storeData.receiverOptions}
                          disabledOptions={this.state.storeData.disabledOptions}
                          periodInDays={this.state.storeData.periodInDays}
                          onChangePreviewData={this.onChangePreviewData}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}

                {!this.state.storeData.isAdmailingAllowed && (
                  <Fragment>
                    <div className="grid__box__sub__header">
                      <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                        <T _={'marketing:admails.notallowed.title'} />
                      </div>
                      <Tooltip position="right" text={_('marketing:admails.tooltip.admailTitle')} />
                    </div>

                    <div className="grid__box__sub__item min-width--0">
                      <div className="grid__box__sub__item__content spinner-container min-width--0">
                        {!this.state.loadingDone && <Spinner />}

                        <NotAllowed
                          isTooLessRankingPoints={this.state.storeData.isTooLessRankingPoints}
                          isPayoutAllowed={this.state.storeData.isPayoutAllowed}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>

            <div className="grid__box__row">
              <Archive
                sortField={this.state.archiveSortField}
                sortDirection={this.state.archiveSortDirection}
              />
            </div>
          </div>
        </div>

        <div className="grid__row min-width--0">
          <div className="grid__column grid__box min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'marketing:admails.create.targetGroup.label'} />
              </div>
            </div>

            <div className="grid__box__row">
              <VXFavourites />
            </div>

            <div className="grid__box__row">
              <VXCashUsers type="promolinkUsers" />
            </div>
          </div>
        </div>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default withAppState(Admails);
